import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import img1 from "../../images/RACK/Slice 1.png";
import img2 from "../../images/RACK/Slice 2.png";
import img3 from "../../images/RACK/Slice 3.png";
import img4 from "../../images/RACK/Slice 4.png";
import img5 from "../../images/RACK/Slice 5.png";
import img6 from "../../images/RACK/Slice 6.png";
import img7 from "../../images/RACK/Slice 7.png";
import img8 from "../../images/RACK/Slice 8.png";
import img9 from "../../images/RACK/Slice 9.png";
import img10 from "../../images/RACK/Slice 10.png";
import img11 from "../../images/RACK/Slice 11.png";
import img12 from "../../images/RACK/Slice 12.png";
import img13 from "../../images/RACK/Slice 13.png";
import img14 from "../../images/RACK/Slice 14.png";
import img15 from "../../images/RACK/Slice 15.png";
import img16 from "../../images/RACK/Slice 16.png";
import img17 from "../../images/RACK/Slice 17.png";
import img18 from "../../images/RACK/Slice 18.png";
import img19 from "../../images/RACK/Slice 19.png";
import img20 from "../../images/RACK/Slice 20.png";
import {
  calculatePercentage,
  calculatePercentageInPixels,
  checkPosition,
  handleConvertToPDF,
  handleExport,
  pxToCm,
} from "../../utilities";
import { Fade, Paper, Popper } from "@mui/material";
import shelfrow from "../../images/shelf-row.png";
import "../shelf/shelf.scss";

const NewShelf = () => {
  //shelf data
  const jsonData = {
    rows: [
      {
        data: [],
        height: "95px",
        occupiedwidth: 0,
        name: "row-1",
      },
      {
        data: [],
        height: "120px",
        occupiedwidth: 0,
        name: "row-2",
      },
      {
        data: [],
        height: "140px",
        occupiedwidth: 0,
        name: "row-3",
      },
      {
        data: [],
        height: "190px",
        occupiedwidth: 0,
        name: "row-4",
      },
    ],
    width: "1200px",
    name: "Products Shelf",
  };

  //products data
  const newProduct = [
    { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
    { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
    { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 2 },
    { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
    { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 4 },
    { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 5 },
    { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
    { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 11 },
    { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
    { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
    { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 1 },
    { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
    { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
    { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
    { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 1 },
    { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
    { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
    { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 2 },
    { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
    { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
  ];

  //row data
  const [rowData, setRowData] = useState(null);

  //storedProducts
  const [storedProducts, setStoredProducts] = useState([]);

  //save for every actions
  const [saveActions, setSaveActions] = useState([]);

  //set unique id
  const [uniqueId, setUniqueId] = useState(1);

  //percentage value
  const [perVal, setPerVal] = useState(null);

  //currnt product while drag start
  const [currentProdcut, setCurrrentProduct] = useState(null);

  // popup
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedItem, setClickedItem] = useState({});
  const [open, setOpen] = useState(false);

  //ref for rowparent(shelf row)
  const rowParentRef = useRef(null);

  //add positions and occupiedwidth & remainingWidth
  const addPositions = (
    res,
    clonedProduct,
    dropX,
    dropY,
    imageX,
    imageY,
    proWidth,
    proHeight,
    updatedRows,
    rowIndex
  ) => {
    // Place the product in the exact position it was dropped
    clonedProduct.position = {
      x: dropX > imageX ? dropX - imageX : 0,
      y: dropY > imageY ? dropY - imageY : 0,
    };

    //clonedproduct start & end positions
    clonedProduct.startEnd = {
      positionStart: dropX > imageX ? dropX - imageX : 0,
      positionEnd: dropX - imageX + parseInt(clonedProduct.width),
    };

    // Check if the total height exceeds the limit
    const totalHeight = clonedProduct.position.y + proHeight;
    const totalWidth = clonedProduct.position.x + proWidth;

    if (totalHeight > parseInt(res.height)) {
      // Calculate the excess height
      const excessHeight = totalHeight - parseInt(res.height);
      // Adjust the Y position to fit within the limit
      clonedProduct.position.y -= excessHeight;
    }

    if (totalWidth > parseInt(rowData.width)) {
      const excessWidth = totalWidth - parseInt(rowData.width);
      clonedProduct.position.x -= excessWidth;
    }

    //add occupiedwidth
    updatedRows[rowIndex].occupiedwidth += proWidth;

    //remove remainingwidth
    updatedRows[rowIndex].remainingwidth -= proWidth;

    console.log("dropXY", dropX, dropY);
    console.log("imageXY", imageX, imageY);

    console.log("clonedProduct.startEnd", clonedProduct.startEnd);
    console.log("clonedProduct.position", clonedProduct.position);
  };

  //triggers while drag start
  const handleDragStart = (e, product, where, rowIwherendex) => {
    e.dataTransfer.setData("product", JSON.stringify(product));
    e.dataTransfer.setData("where", where);
    e.dataTransfer.setData("index", rowIwherendex);

    setCurrrentProduct(product);

    // Capture the initial mouse click position relative to the image
    const imageX = e.clientX - e.target.getBoundingClientRect().left;
    const imageY = e.clientY - e.target.getBoundingClientRect().top;

    e.dataTransfer.setData("imageX", imageX);
    e.dataTransfer.setData("imageY", imageY);

    // You can use imageX and imageY for your needs
    console.log("Initial image click position - X:", imageX, "Y:", imageY);
  };

  //triggers while dropping item
  const handleDrop = (e, rowIndex, res) => {
    const product = JSON.parse(e.dataTransfer.getData("product"));
    const place = e.dataTransfer.getData("where");
    const indexValue = e.dataTransfer.getData("index");
    const imageX = Number(e.dataTransfer.getData("imageX"));
    const imageY = Number(e.dataTransfer.getData("imageY"));

    let resHeight = parseInt(res.height);
    let proHeight = parseInt(product.height);
    let proWidth = parseInt(product.width);

    // Get the drop position relative to the rowParent
    const rowParent = e.currentTarget;
    const rect = rowParent.getBoundingClientRect();
    // X position relative to the rowParent
    const dropX = e.clientX - rect.left;
    // Y position relative to the rowParent
    const dropY = e.clientY - rect.top;

    //drag and dropped image
    const droppedImage = {
      x: Number(dropX > imageX ? dropX - imageX : 0),
      y: Number(dropY > imageY ? dropY - imageY : 0),
      width: proWidth,
      height: proHeight,
    };

    console.log("droppedImage", droppedImage);
    console.log("handleDrop res: ", res)

    // Check if the dropped product is fit for this row
    if (proHeight < resHeight && res.remainingwidth >= proWidth) {
      // Check if dragged from new products
      if (place === "fromNewProducts") {
        const clonedProduct = { ...product };
        const updatedRows = [...rowData.rows];

        // Set a new unique ID
        clonedProduct.defaultId = product.id;
        clonedProduct.id = `uniqueId-${uniqueId}`;
        setUniqueId(uniqueId + 1);

        //add all positions and occupiedWidth and remainingWidth
        addPositions(
          res,
          clonedProduct,
          dropX,
          dropY,
          imageX,
          imageY,
          proWidth,
          proHeight,
          updatedRows,
          rowIndex
        );

        updatedRows[rowIndex].data.push(clonedProduct);

        //calculate item counts
        calculateCounts("decrease", clonedProduct);

        setRowData({ ...rowData, rows: updatedRows });

        console.log("fromNewProducts runsss");
      }

      if (e.ctrlKey && place === "fromShelfParent") {
        // Check if the drag event includes the Ctrl key being pressed
        //clone and add image, when its clicked in shelfParent
        const clonedProduct = { ...product };
        const updatedRows = [...rowData.rows];

        if (isClonable(clonedProduct)) {
          // Set a new unique ID
          clonedProduct.id = `uniqueId-${uniqueId}`;
          setUniqueId(uniqueId + 1);

          //add all positions and occupiedWidth and remainingWidth
          addPositions(
            res,
            clonedProduct,
            dropX,
            dropY,
            imageX,
            imageY,
            proWidth,
            proHeight,
            updatedRows,
            rowIndex
          );

          updatedRows[rowIndex].data.push(clonedProduct);

          //calculate item counts
          calculateCounts("decrease", clonedProduct);

          setRowData({ ...rowData, rows: updatedRows });
        }
      } else if (place === "fromShelfParent") {
        const clonedProduct = { ...product };
        const updatedRows = [...rowData.rows];

        const findInd = updatedRows[indexValue].data.findIndex(
          (item) => item.id === clonedProduct.id
        );

        //add all positions and occupiedWidth and remainingWidth
        addPositions(
          res,
          clonedProduct,
          dropX,
          dropY,
          imageX,
          imageY,
          proWidth,
          proHeight,
          updatedRows,
          rowIndex
        );

        updatedRows[rowIndex].data.push(clonedProduct);
        updatedRows[indexValue].data.splice(findInd, 1);

        updatedRows[indexValue].occupiedwidth -= proWidth;
        updatedRows[indexValue].remainingwidth += proWidth;

        setRowData({ ...rowData, rows: updatedRows });

        console.log("fromShelfParent runsss");
      }
    } else {
      toast.error(`${product.name} doesn't fit in this row, try other rows!`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setCurrrentProduct(null);
    e.target.style.backgroundColor = "transparent";
  };

  // remove image when dropped outside shelf
  const handleDocumentDrop = (event) => {
    event.preventDefault();

    const shelfParent = document.querySelector(".shelfParent");

    //check the image dropped inside or outside shelfParent
    if (!shelfParent.contains(event.target)) {
      const product = JSON.parse(event.dataTransfer.getData("product"));
      const indexValue = event.dataTransfer.getData("index");

      // Check if rowData and indexValue exist
      if (rowData && rowData.rows && indexValue !== "") {
        const updatedRows = [...rowData.rows];
        const rowIndex = parseInt(indexValue);
        const clonedProduct = { ...product };

        // Check if rowIndex is within the valid range
        if (rowIndex >= 0 && rowIndex < updatedRows.length) {
          const row = updatedRows[rowIndex];

          // Check if row.occupiedwidth is defined
          if (row && typeof row.occupiedwidth !== "undefined") {
            const productIndex = row.data.findIndex(
              (item) => product.id === item.id
            );

            if (productIndex !== -1) {
              // Remove the product from the row
              row.data.splice(productIndex, 1);

              // Update occupiedwidth and remainingwidth
              row.occupiedwidth -= parseInt(product.width);
              row.remainingwidth += parseInt(product.width);

              //calculate item counts
              calculateCounts("increase", clonedProduct);

              setRowData({ ...rowData, rows: updatedRows });

              // display toast when image removed successfully
              toast.success(`${product.name} removed successfully`, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          } else {
            console.error("Invalid row data: occupiedwidth is undefined");
          }
        } else {
          console.error("Invalid rowIndex: Out of range");
        }
      } else {
        console.error("Invalid rowData or indexValue");
      }
    }
  };

  //while image dragging hover
  const handleDocumentDragOver = (event) => {
    event.preventDefault();
  };

  //get width of the rowParent
  const getDivWidth = () => {
    const div = rowParentRef?.current;
    const width = div?.offsetWidth;
    let val = calculatePercentage(parseInt(width), parseInt(rowData?.width));
    setPerVal(val);
  };

  //mouse hover while drag
  const handleDragOver = (e, res) => {
    e.preventDefault();
    let dom = e.target;

    if (
      parseInt(calculatePercentageInPixels(res?.height, perVal)) >=
      parseInt(calculatePercentageInPixels(currentProdcut?.height, perVal))
    ) {
      dom.style.backgroundColor = "green";
    } else {
      dom.style.backgroundColor = "darkred";
    }
  };

  //mouse leave while drag
  const handleDragLeave = (e) => {
    e.preventDefault();
    let dom = e.target;
    dom.style.backgroundColor = "transparent";
  };

  //handle popup click (open popup and set currently clicked item)
  const handleClick = (event, product, rowIndex, i) => {
    if (!open || clickedItem.itemIndex !== i || clickedItem.rowIndex !== rowIndex) {
      setAnchorEl(event.currentTarget);
      setClickedItem({
        product: product,
        itemIndex: i,
        rowIndex: rowIndex
      });
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  //handle delet from popup
  const handleDelete = (product) => {
    const updatedRows = [...rowData.rows];
    const row = updatedRows[clickedItem.rowIndex];
    const clonedProduct = { ...product };

    row.data.splice(clickedItem.itemIndex, 1);

    // Update occupiedwidth and remainingwidth
    row.occupiedwidth -= parseInt(product.width);
    row.remainingwidth += parseInt(product.width);

    //calculate item counts
    calculateCounts("increase", clonedProduct);

    setRowData({ ...rowData, rows: updatedRows });

    // display toast when image removed successfully
    toast.success(`${product.name} removed successfully`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    setOpen(false);
  };

  //calculate item counts (ramaining)
  const calculateCounts = (action, clonedProduct) => {
    const heredata = storedProducts.find((item) => {
      return item.id === clonedProduct.defaultId;
    });

    if (action === "increase") {
      heredata.count = heredata.count + 1;
    } else {
      heredata.count = heredata.count - 1;
    }
  };

  //check counts when it drag fromy
  const isClonable = (product) => {
    const findData = storedProducts.find((item) => {
      return item.id === product.defaultId;
    })
    if (findData.count === 0) {
      return false;
    } else {
      return true;
    }
  };

  //set location id for every dropped item
  const locationId = () => {
    rowData.rows.map((item, rowIndex) => {
      return item.data.map((res, columnIndex) => {
        return res.locationId = `row-${rowIndex + 1}-${columnIndex + 1}`
      })
    })
  }

  //useeffect set json to state
  useEffect(() => {
    //add remainingwidth for every shelf
    const modifiedJsonData = {
      ...jsonData,
      rows: jsonData.rows.map((row) => ({
        ...row,
        remainingwidth: parseInt(jsonData.width),
      })),
    };
    setRowData(modifiedJsonData);
    setStoredProducts(newProduct);
  }, []);

  //useeffect for get width
  useEffect(() => {
    getDivWidth();
  }, [rowData]);

  //logs
  console.log("rowData", rowData);
  console.log("setStoredProducts", storedProducts);

  //listners for (remove image when dropped outside shelf)
  document.addEventListener("drop", handleDocumentDrop);
  document.addEventListener("dragover", handleDocumentDragOver);

  // Add a resize event listener to get the width when the screen size changes
  window.addEventListener("resize", getDivWidth);

  return (
    <>
      <div className="shelf">
        <h1 style={{ textAlign: "center" }}>{rowData?.name}</h1>

        <div style={{ display: "flex", width: "100%" }}>
          <div className="newProductParent" style={{ width: "25%" }}>
            {storedProducts?.map((item) => {
              return (
                <button
                  key={item.id}
                  className="newProductChild"
                  onDragStart={(e) => handleDragStart(e, item, "fromNewProducts")}
                  draggable={item.count > 0}
                  disabled={item.count === 0}
                  style={{ cursor: item.count === 0 ? "not-allowed" : "pointer" }}
                >
                  <img
                    src={item.img}
                    style={{
                      height: calculatePercentageInPixels(item.height, perVal),
                      width: calculatePercentageInPixels(item.width, perVal),
                      opacity: item.count === 0 ? 0.3 : 1
                    }}
                    alt={item.name}
                    draggable={item.count > 0}
                  />
                  {item.count === 0 && <div className="outofstock">out of stock</div>}
                </button>
              );
            })}
          </div>

          <div style={{ width: "75%" }}>
            <div className="shelfParent">
              {rowData?.rows?.map((res, rowIndex) => {
                return (
                  <div
                    key={rowIndex}
                    style={{ display: "flex", columnGap: "10px" }}
                  >
                    <div
                      ref={rowParentRef}
                      key={rowIndex}
                      id={`table-${rowIndex}`}
                      draggable={false}
                      className="rowParent"
                      style={{
                        height: calculatePercentageInPixels(res.height, perVal),
                        width: rowData.width,
                        // background: `url(${shelfrow})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: `${parseInt(rowData.width)}px ${parseInt(res.height)}px`
                      }}
                      onDrop={(e) => handleDrop(e, rowIndex, res)}
                      onDragOver={(e) => {
                        handleDragOver(e, res, rowIndex);
                      }}
                      onDragLeave={(e) => {
                        handleDragLeave(e);
                      }}
                      onMouseDown={(e) => checkPosition(e)} // X and Y position (onClick)
                    >
                      {res.data?.map((product, i) => {
                        const style = product.position
                          ? {
                            left: calculatePercentageInPixels(
                              product.position.x,
                              perVal
                            ),
                            // top: calculatePercentageInPixels(
                            //   product.position.y,
                            //   perVal
                            // ),
                          }
                          : null;
                        return (
                          <>
                            <img
                              key={product.id}
                              style={{
                                width: calculatePercentageInPixels(
                                  product.width,
                                  perVal
                                ),
                                height: calculatePercentageInPixels(
                                  product.height,
                                  perVal
                                ),
                                position: "absolute",
                                bottom: "0px",
                                ...style,
                              }}
                              draggable
                              onDragStart={(e) =>
                                handleDragStart(
                                  e,
                                  product,
                                  "fromShelfParent",
                                  rowIndex
                                )
                              }
                              src={product.img}
                              alt={product.name}

                              onClick={(e) => {
                                handleClick(e, product, rowIndex, i)
                              }}
                            />

                            {open && <Popper open={open} anchorEl={anchorEl} placement={"top"} transition>
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper sx={{ p: 2 }}>
                                    <div>
                                      <p style={{ marginBottom: "0px" }}> <b>name :</b> {clickedItem.product?.name}</p>
                                      <p><b>location id :</b>{` row-${clickedItem.rowIndex + 1}-${clickedItem.itemIndex + 1}`}</p>

                                      <div style={{ display: "flex", columnGap: "10px" }}>
                                        <button onClick={() => handleDelete(product)} className="popup_delete">Delete</button>
                                        <button onClick={(ev) => setOpen(false)} className="popup_close">Close</button>
                                      </div>
                                    </div>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>}
                          </>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <div className="rowHeight"></div>
                      <p
                        style={{
                          color: "black",
                          alignSelf: "center",
                          fontSize: `${perVal}%`,
                        }}
                      >
                        Height : {pxToCm(parseInt(res.height)).toFixed(2)} CM
                        <br />
                        Products : {`${res?.data.length}`} Items
                        <br />
                        Name : {`${res.name}`}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <p style={{ textAlign: "center", marginTop: "25px" }}>
            Total Shelf Width : {pxToCm(parseInt(rowData?.width)).toFixed(2)} CM
          </p> */}
            {/* <div
            className="showWidth"
            style={{ width: `${perVal == 100 ? perVal - 20 : 86}%` }}
          ></div>
          <p style={{ textAlign: "center" }}>
            Total Shelf Width : {pxToCm(parseInt(rowData?.width)).toFixed(2)} CM
          </p> */}

            <div style={{ display: "flex", columnGap: "15px", alignItems: "baseline", flexDirection: "row-reverse", justifyContent: "space-evenly" }}>
              <div className="btnsParent">
                <button
                  className="pdfBtn"
                  onClick={() => {
                    handleConvertToPDF("shelfParent", "planogram");
                    toast.success(`Converted successfully`, {
                      position: toast.POSITION.BOTTOM_LEFT,
                    });
                  }}
                >
                  Convert to PDF
                </button>

                <button
                  className="btnExport"
                  onClick={() => {
                    locationId()
                    handleExport(rowData);
                    toast.success(`Exported successfully`, {
                      position: toast.POSITION.BOTTOM_LEFT,
                    });
                  }}
                >
                  Export as JSON
                </button>
              </div>

              <div style={{ display: "flex", columnGap: "15px" }}>
                <button className="undoredo">undo</button>
                <button className="undoredo">redo</button>
              </div>
            </div>
          </div>
        </div >

        <ToastContainer />

      </div>
    </>
  );
};

export default NewShelf;