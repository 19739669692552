import { useState, useEffect } from 'react';

const useFullscreen = (elementRef) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleFullScreen = () => {
        const element = elementRef.current;

        if (!isFullScreen) {
            element.requestFullscreen?.() ||
                element.mozRequestFullScreen?.() ||
                element.webkitRequestFullscreen?.() ||
                element.msRequestFullscreen?.();
        } else {
            document.exitFullscreen?.() ||
                document.mozCancelFullScreen?.() ||
                document.webkitExitFullscreen?.() ||
                document.msExitFullscreen?.();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape' && isFullScreen) {
            handleFullScreen();
        }
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullScreen]);

    return {
        isFullScreen,
        toggleFullScreen: handleFullScreen,
    };
};

export default useFullscreen;
