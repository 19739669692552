import { Offcanvas } from "react-bootstrap";

const OffcanvasPopup = (props) => {
    return (
        <>
            <Offcanvas className={`${props.classname}`} show={props.show} placement={"end"} onHide={props.hide}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="categorytitle">{props.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {props.OffcanvasBody()}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default OffcanvasPopup;