import { Routes, Route } from "react-router-dom";
import Login from "./components/login/login";
import Shelf from "./components/shelf/shelf";
import PlanogramShelf from "./components/newshelf/planogram-shelf";
import "../src/App.css";

function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/shelf" element={<Shelf />} />
        <Route path="/" element={<PlanogramShelf />} />
      </Routes>
    </>
  );
}

export default App;
