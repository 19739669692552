import { useEffect, useRef, useState } from "react";
import "../newshelf/planogram-shelf.scss";
import axios from "axios";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from "react-toastify";
import File from "../../images/file.svg";
import imports from "../../images/import.svg";
import undo from "../../images/undo.svg";
import redo from "../../images/redo.svg";
import pdf from "../../images/pdf.svg";
import exports from "../../images/export.svg";
import search from "../../images/search.svg";
import Bulb from "../../images/bulb.svg";
import Fullscreen from "../../images/fullscreen.svg";
import Zoomout from "../../images/zoomout.svg";
import Zoomin from "../../images/zoomin.svg";
import shelfHole from "../../images/whole.svg";
import shelf from "../../images/shelf.png";
import Base from "../../images/base box.png";
import Rectangles from "../../images/Rectangle.png";
import Company from "../../images/navabrind.png";
import img1 from "../../images/RACK/Slice 1.png";
import img2 from "../../images/RACK/Slice 2.png";
import img3 from "../../images/RACK/Slice 3.png";
import img4 from "../../images/RACK/Slice 4.png";
import img5 from "../../images/RACK/Slice 5.png";
import img6 from "../../images/RACK/Slice 6.png";
import img7 from "../../images/RACK/Slice 7.png";
import img8 from "../../images/RACK/Slice 8.png";
import img9 from "../../images/RACK/Slice 9.png";
import img10 from "../../images/RACK/Slice 10.png";
import img11 from "../../images/RACK/Slice 11.png";
import img12 from "../../images/RACK/Slice 12.png";
import img13 from "../../images/RACK/Slice 13.png";
import img14 from "../../images/RACK/Slice 14.png";
import img15 from "../../images/RACK/Slice 15.png";
import img16 from "../../images/RACK/Slice 16.png";
import img17 from "../../images/RACK/Slice 17.png";
import img18 from "../../images/RACK/Slice 18.png";
import img19 from "../../images/RACK/Slice 19.png";
import img20 from "../../images/RACK/Slice 20.png";
import { calculatePercentage, calculatePercentageInPixels, checkPosition, handleConvertToPDF, handleExport } from "../../utilities";
import useFullscreen from "../../customHook/fullScreen";
import OffcanvasPopup from "./offcanvas";

const PlanogramShelf = () => {

    const newProduct = [{
        category: "toys",
        categoryID: 987,
        name: "Cars & toys",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 2 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 4 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 5 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 11 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 1 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 1 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 2 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "toys",
        categoryID: 564,
        name: "Airplanes",
        productsData: [
            { id: 101, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 102, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 103, img: img3, name: "product 3", height: "85px", width: "65px", count: 2 },
            { id: 104, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 105, img: img5, name: "product 5", height: "100px", width: "87px", count: 4 },
            { id: 106, img: img6, name: "product 6", height: "80px", width: "65px", count: 5 },
            { id: 107, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 108, img: img8, name: "product 9", height: "130px", width: "100px", count: 11 },
            { id: 109, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 110, img: img10, name: "product 10", height: "125px", width: "78px", count: 2 },
            { id: 111, img: img11, name: "product 11", height: "100px", width: "60px", count: 7 },
            { id: 112, img: img12, name: "product 12", height: "107px", width: "89px", count: 1 },
            { id: 113, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
            { id: 114, img: img14, name: "product 14", height: "95px", width: "98px", count: 7 },
            { id: 115, img: img15, name: "product 15", height: "125px", width: "87px", count: 1 },
            { id: 116, img: img16, name: "product 16", height: "150px", width: "100px", count: 7 },
            { id: 117, img: img17, name: "product 17", height: "155px", width: "91px", count: 3 },
            { id: 118, img: img18, name: "product 18", height: "120px", width: "56px", count: 0 },
            { id: 119, img: img19, name: "product 19", height: "110px", width: "90px", count: 2 },
            { id: 120, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "toys",
        categoryID: 629,
        name: "Boats",
        productsData: [
            { id: 201, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 202, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 203, img: img3, name: "product 3", height: "85px", width: "65px", count: 2 },
            { id: 204, img: img4, name: "product 4", height: "90px", width: "100px", count: 10 },
            { id: 205, img: img5, name: "product 5", height: "100px", width: "87px", count: 2 },
            { id: 206, img: img6, name: "product 6", height: "80px", width: "65px", count: 5 },
            { id: 207, img: img7, name: "product 7", height: "142px", width: "98px", count: 5 },
            { id: 208, img: img8, name: "product 9", height: "130px", width: "100px", count: 11 },
            { id: 209, img: img9, name: "product 9", height: "135px", width: "91px", count: 1 },
            { id: 210, img: img10, name: "product 10", height: "125px", width: "78px", count: 8 },
            { id: 211, img: img11, name: "product 11", height: "100px", width: "60px", count: 2 },
            { id: 212, img: img12, name: "product 12", height: "107px", width: "89px", count: 8 },
            { id: 213, img: img13, name: "product 13", height: "90px", width: "73px", count: 11 },
            { id: 214, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 215, img: img15, name: "product 15", height: "125px", width: "87px", count: 1 },
            { id: 216, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 217, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 218, img: img18, name: "product 18", height: "120px", width: "56px", count: 2 },
            { id: 219, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 220, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "comics",
        categoryID: 542,
        name: "hulk",
        productsData: [
            { id: 301, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 302, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 303, img: img3, name: "product 3", height: "85px", width: "65px", count: 12 },
            { id: 304, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 305, img: img5, name: "product 5", height: "100px", width: "87px", count: 4 },
            { id: 306, img: img6, name: "product 6", height: "80px", width: "65px", count: 2 },
            { id: 307, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 308, img: img8, name: "product 9", height: "130px", width: "100px", count: 11 },
            { id: 309, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 310, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 311, img: img11, name: "product 11", height: "100px", width: "60px", count: 1 },
            { id: 312, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
            { id: 313, img: img13, name: "product 13", height: "90px", width: "73px", count: 1 },
            { id: 314, img: img14, name: "product 14", height: "95px", width: "98px", count: 4 },
            { id: 315, img: img15, name: "product 15", height: "125px", width: "87px", count: 1 },
            { id: 316, img: img16, name: "product 16", height: "150px", width: "100px", count: 0 },
            { id: 317, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 318, img: img18, name: "product 18", height: "120px", width: "56px", count: 2 },
            { id: 319, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 320, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "toys",
        categoryID: 1987,
        name: "Cars & Boats",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 12 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 4 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 5 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 11 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 11 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 11 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 23 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 13 },
        ]
    }, {
        category: "toys",
        categoryID: 917,
        name: "bikes & toys",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 2 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 10 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 0 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 5 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 13 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 11 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 31 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 12 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 1 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 12 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 2 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    }, {
        category: "toys",
        categoryID: 187,
        name: "hulk & ben10",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 11 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 12 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 24 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 5 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 11 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 1 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 12 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 1 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 2 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "toys",
        categoryID: 507,
        name: "bike & toys",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 1 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 2 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 4 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 5 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 11 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 10 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 1 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 2 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "toys",
        categoryID: 87,
        name: "animals",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 2 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 4 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 57 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 31 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 1 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 1 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 2 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "toys",
        categoryID: 287,
        name: "wooden toys",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 10 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 12 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 14 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 7 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 31 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 1 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 14 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 10 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 12 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "toys",
        categoryID: 602,
        name: "numbers",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 2 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 4 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 57 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 31 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 1 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 13 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 12 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 11 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 12 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 13 },
        ]
    }, {
        category: "toys",
        categoryID: 925,
        name: "abcd",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 1 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 14 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 2 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 10 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 4 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 57 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 31 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 4 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 11 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 63 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 4 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 11 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 2 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "toys",
        categoryID: 27,
        name: "baby doll",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 10 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 4 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 12 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 14 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 14 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 31 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 43 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 24 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 1 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 14 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 10 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 12 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    {
        category: "toys",
        categoryID: 217,
        name: "iron toys",
        productsData: [
            { id: 1, img: img1, name: "product 1", height: "115px", width: "90px", count: 10 },
            { id: 2, img: img2, name: "product 2", height: "100px", width: "80px", count: 14 },
            { id: 3, img: img3, name: "product 3", height: "85px", width: "65px", count: 12 },
            { id: 4, img: img4, name: "product 4", height: "90px", width: "100px", count: 0 },
            { id: 5, img: img5, name: "product 5", height: "100px", width: "87px", count: 14 },
            { id: 6, img: img6, name: "product 6", height: "80px", width: "65px", count: 17 },
            { id: 7, img: img7, name: "product 7", height: "142px", width: "98px", count: 3 },
            { id: 8, img: img8, name: "product 9", height: "130px", width: "100px", count: 31 },
            { id: 9, img: img9, name: "product 9", height: "135px", width: "91px", count: 14 },
            { id: 10, img: img10, name: "product 10", height: "125px", width: "78px", count: 4 },
            { id: 11, img: img11, name: "product 11", height: "100px", width: "60px", count: 1 },
            { id: 12, img: img12, name: "product 12", height: "107px", width: "89px", count: 3 },
            { id: 13, img: img13, name: "product 13", height: "90px", width: "73px", count: 14 },
            { id: 14, img: img14, name: "product 14", height: "95px", width: "98px", count: 2 },
            { id: 15, img: img15, name: "product 15", height: "125px", width: "87px", count: 10 },
            { id: 16, img: img16, name: "product 16", height: "150px", width: "100px", count: 2 },
            { id: 17, img: img17, name: "product 17", height: "155px", width: "91px", count: 0 },
            { id: 18, img: img18, name: "product 18", height: "120px", width: "56px", count: 12 },
            { id: 19, img: img19, name: "product 19", height: "110px", width: "90px", count: 4 },
            { id: 20, img: img20, name: "product 20", height: "115px", width: "110px", count: 3 },
        ]
    },
    ];

    //shelf data
    const jsonData = {
        rows: [
            {
                data: [],
                height: "95px",
                occupiedwidth: 0,
                name: "row-1",
            },
            {
                data: [],
                height: "120px",
                occupiedwidth: 0,
                name: "row-2",
            },
            {
                data: [],
                height: "140px",
                occupiedwidth: 0,
                name: "row-3",
            },
            {
                data: [],
                height: "190px",
                occupiedwidth: 0,
                name: "row-4",
            },
        ],
        width: "1000px",
        name: "Products Shelf",
    };

    //row data
    const [rowData, setRowData] = useState(null);

    //storedProducts
    const [storedProducts, setStoredProducts] = useState([]);

    //acordion expand
    const [expanded, setExpanded] = useState(false);

    //open searchbar
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    //show clicked items properties
    const [showProperty, setShowProperty] = useState(false);

    //set unique id
    const [uniqueId, setUniqueId] = useState(1);

    //percentage value
    const [perVal, setPerVal] = useState(null);

    //currnt product while drag start
    const [currentProdcut, setCurrrentProduct] = useState(null);

    // clickedItem
    const [clickedItem, setClickedItem] = useState({});

    //imported json data
    const [importJsonData, setImportJsonData] = useState(null);

    // zoom 
    const [zoomPercentage, setZoomPercentage] = useState(100);

    //adjust height popup
    const [adjustHeightPopup, setHeightAdjustPopup] = useState(false);

    //selected rack
    const [selectedRack, setSelectedRack] = useState({});

    //edited height
    const [editedHeight, setEditedHeight] = useState(0);

    //file ref
    const fileInputRef = useRef(null);

    //ref for rowparent(shelf row)
    const rowParentRef = useRef(null);

    //shelf parent reference
    const shelfParentRef = useRef(null);

    //custom hook for fullscreen
    const { isFullScreen, toggleFullScreen } = useFullscreen(shelfParentRef);

    //useeffect set json to state
    useEffect(() => {

        //fetch API data
        fetchData();

        //add remainingwidth for every shelf
        const modifiedJsonData = {
            ...jsonData,
            rows: jsonData.rows.map((row) => ({
                ...row,
                remainingwidth: parseInt(jsonData.width),
            })),
        };

        // Adding categoryIndex property to each product object
        const updatedProductData = newProduct.map((category, categoryIndex) => {
            const productsWithIndex = category.productsData.map(product => ({
                ...product,
                categoryIndex,
            }));

            return {
                ...category,
                productsData: productsWithIndex,
            };
        });

        setRowData(modifiedJsonData);
        setStoredProducts(updatedProductData);
    }, []);

    //useeffect for get width
    useEffect(() => {
        getDivWidth();
    }, [rowData]);

    // API Endpoint : https://hemkumarnits-planogram-api1-stag-10960529.dev.odoo.com/get_products
    // Headers : Content-type : application/json
    // Body :  {"params":{"db":"hemkumarnits-planogram-api1-stag-10960529","login":"admin","password":"admin123"}}
    const fetchData = () => {
        try {
            // Replace these with your actual credentials
            const params = {
                params: {
                    db: 'hemkumarnits-planogram-api1-stag-10960529',
                    login: 'admin',
                    password: 'admin123',
                },
            };
            // Make a POST request using Axios
            axios.post('https://hemkumarnits-planogram-api1-stag-10960529.dev.odoo.com/get_products', params, {
                headers: {
                    'Content-type': 'application/json',
                },
            })
                .then(response => {
                    // Handle the successful response
                    console.log("response.data", response.data);
                })
                .catch(error => {
                    // Handle errors
                    console.error(`Error: ${error.message}`);
                });
        }
        catch (err) {
            return err;
        }
    };

    //add positions and occupiedwidth & remainingWidth
    const addPositions = (
        res,
        clonedProduct,
        dropX,
        dropY,
        imageX,
        imageY,
        proWidth,
        proHeight,
        updatedRows,
        rowIndex
    ) => {
        // Place the product in the exact position it was dropped
        clonedProduct.position = {
            x: dropX > imageX ? dropX - imageX : 0,
            y: dropY > imageY ? dropY - imageY : 0,
        };

        //clonedproduct start & end positions
        clonedProduct.startEnd = {
            positionStart: dropX > imageX ? dropX - imageX : 0,
            positionEnd: dropX - imageX + parseInt(clonedProduct.width),
        };

        // Check if the total height exceeds the limit
        const totalHeight = clonedProduct.position.y + proHeight;
        const totalWidth = clonedProduct.position.x + proWidth;

        if (totalHeight > parseInt(res.height)) {
            // Calculate the excess height
            const excessHeight = totalHeight - parseInt(res.height);
            // Adjust the Y position to fit within the limit
            clonedProduct.position.y -= excessHeight;
        }

        if (totalWidth > parseInt(rowData.width)) {
            const excessWidth = totalWidth - parseInt(rowData.width);
            clonedProduct.position.x -= excessWidth;
        }

        //add occupiedwidth
        updatedRows[rowIndex].occupiedwidth += proWidth;

        //remove remainingwidth
        updatedRows[rowIndex].remainingwidth -= proWidth;

        console.log("dropXY", dropX, dropY);
        console.log("imageXY", imageX, imageY);

        console.log("clonedProduct.startEnd", clonedProduct.startEnd);
        console.log("clonedProduct.position", clonedProduct.position);
    };

    //triggers while drag start
    const handleDragStart = (e, product, where, rowIndex) => {
        e.dataTransfer.setData("product", JSON.stringify(product));
        e.dataTransfer.setData("where", where);
        e.dataTransfer.setData("index", rowIndex);

        setCurrrentProduct(product);

        // Capture the initial mouse click position relative to the image
        const imageX = e.clientX - e.target.getBoundingClientRect().left;
        const imageY = e.clientY - e.target.getBoundingClientRect().top;

        e.dataTransfer.setData("imageX", imageX);
        e.dataTransfer.setData("imageY", imageY);

        // You can use imageX and imageY for your needs
        console.log("Initial image click position - X:", imageX, "Y:", imageY);

    };

    //triggers while dropping item
    const handleDrop = (e, rowIndex, res) => {
        const product = JSON.parse(e.dataTransfer.getData("product"));
        const place = e.dataTransfer.getData("where");
        const indexValue = e.dataTransfer.getData("index");
        const imageX = Number(e.dataTransfer.getData("imageX"));
        const imageY = Number(e.dataTransfer.getData("imageY"));

        let resHeight = parseInt(res.height);
        let proHeight = parseInt(product.height);
        let proWidth = parseInt(product.width);

        // Get the drop position relative to the rowParent
        const rowParent = e.currentTarget;
        const rect = rowParent.getBoundingClientRect();
        // X position relative to the rowParent
        const dropX = e.clientX - rect.left;
        // Y position relative to the rowParent
        const dropY = e.clientY - rect.top;

        //drag and dropped image
        const droppedImage = {
            x: Number(dropX > imageX ? dropX - imageX : 0),
            y: Number(dropY > imageY ? dropY - imageY : 0),
            width: proWidth,
            height: proHeight,
        };

        console.log("droppedImage", droppedImage);
        console.log("handleDrop res: ", res)

        // Check if the dropped product is fit for this row
        if (proHeight < resHeight && res.remainingwidth >= proWidth) {
            // Check if dragged from new products
            if (place === "fromNewProducts") {
                const clonedProduct = { ...product };
                const updatedRows = [...rowData.rows];

                // Set a new unique ID
                clonedProduct.defaultId = product.id;
                clonedProduct.id = `uniqueId-${uniqueId}`;
                clonedProduct.rowNumber = rowIndex;
                delete clonedProduct.count;
                setUniqueId(uniqueId + 1);

                //add all positions and occupiedWidth and remainingWidth
                addPositions(
                    res,
                    clonedProduct,
                    dropX,
                    dropY,
                    imageX,
                    imageY,
                    proWidth,
                    proHeight,
                    updatedRows,
                    rowIndex
                );

                updatedRows[rowIndex].data.push(clonedProduct);

                //calculate item counts
                calculateCounts("fromNewProducts", clonedProduct);

                setRowData({ ...rowData, rows: updatedRows });

                console.log("fromNewProducts runsss");
            }

            if (e.ctrlKey && place === "fromShelfParent") {
                // Check if the drag event includes the Ctrl key being pressed
                //clone and add image, when its clicked in shelfParent
                const clonedProduct = { ...product };
                const updatedRows = [...rowData.rows];

                if (isClonable(clonedProduct)) {
                    // Set a new unique ID
                    clonedProduct.id = `uniqueId-${uniqueId}`;
                    clonedProduct.rowNumber = rowIndex;
                    delete clonedProduct.count;
                    setUniqueId(uniqueId + 1);

                    //add all positions and occupiedWidth and remainingWidth
                    addPositions(
                        res,
                        clonedProduct,
                        dropX,
                        dropY,
                        imageX,
                        imageY,
                        proWidth,
                        proHeight,
                        updatedRows,
                        rowIndex
                    );

                    updatedRows[rowIndex].data.push(clonedProduct);

                    //calculate item counts
                    calculateCounts("cloneFromShelfParent", clonedProduct);

                    setRowData({ ...rowData, rows: updatedRows });
                }
            } else if (place === "fromShelfParent") {
                const clonedProduct = { ...product };
                const updatedRows = [...rowData.rows];

                const findInd = updatedRows[indexValue].data.findIndex(
                    (item) => item.id === clonedProduct.id
                );

                clonedProduct.rowNumber = rowIndex;

                //add all positions and occupiedWidth and remainingWidth
                addPositions(
                    res,
                    clonedProduct,
                    dropX,
                    dropY,
                    imageX,
                    imageY,
                    proWidth,
                    proHeight,
                    updatedRows,
                    rowIndex
                );

                updatedRows[rowIndex].data.push(clonedProduct);
                updatedRows[indexValue].data.splice(findInd, 1);

                updatedRows[indexValue].occupiedwidth -= proWidth;
                updatedRows[indexValue].remainingwidth += proWidth;

                setRowData({ ...rowData, rows: updatedRows });

                console.log("fromShelfParent runsss");
            }
        } else {
            toast.error(`${product.name} doesn't fit in this row, try other rows!`, {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
        setCurrrentProduct(null);
        // e.target.style.backgroundColor = "transparent";
    };

    // remove image when dropped outside shelf
    const handleDocumentDrop = (event) => {
        event.preventDefault();

        const shelfParent = document.querySelector(".shelfParent");

        //check the image dropped inside or outside shelfParent
        if (!shelfParent.contains(event.target)) {
            const product = JSON.parse(event.dataTransfer.getData("product"));
            const indexValue = event.dataTransfer.getData("index");

            // Check if rowData and indexValue exist
            if (rowData && rowData.rows && indexValue !== "") {
                const updatedRows = [...rowData.rows];
                const rowIndex = parseInt(indexValue);
                const clonedProduct = { ...product };

                // Check if rowIndex is within the valid range
                if (rowIndex >= 0 && rowIndex < updatedRows.length) {
                    const row = updatedRows[rowIndex];

                    // Check if row.occupiedwidth is defined
                    if (row && typeof row.occupiedwidth !== "undefined") {
                        const productIndex = row.data.findIndex(
                            (item) => product.id === item.id
                        );

                        if (productIndex !== -1) {
                            // Remove the product from the row
                            row.data.splice(productIndex, 1);

                            // Update occupiedwidth and remainingwidth
                            row.occupiedwidth -= parseInt(product.width);
                            row.remainingwidth += parseInt(product.width);

                            //calculate item counts
                            calculateCounts("dragDeleteItem", clonedProduct);

                            setRowData({ ...rowData, rows: updatedRows });

                            // display toast when image removed successfully
                            toast.success(`${product.name} removed successfully`, {
                                position: toast.POSITION.BOTTOM_LEFT,
                            });

                            setShowProperty(false);
                        }
                    } else {
                        console.error("Invalid row data: occupiedwidth is undefined");
                    }
                } else {
                    console.error("Invalid rowIndex: Out of range");
                }
            } else {
                console.error("Invalid rowData or indexValue");
            }
        }
    };

    //while image dragging hover
    const handleDocumentDragOver = (event) => {
        event.preventDefault();
    };

    //get width of the rowParent
    const getDivWidth = () => {
        const div = rowParentRef?.current;
        const width = div?.offsetWidth;
        let val = calculatePercentage(parseInt(width), parseInt(rowData?.width));
        setPerVal(val);
    };

    //mouse hover while drag
    const handleDragOver = (e, res) => {
        e.preventDefault();
        let dom = e.target;

        // if (
        //     parseInt(calculatePercentageInPixels(res?.height, perVal)) >=
        //     parseInt(calculatePercentageInPixels(currentProdcut?.height, perVal))
        // ) {
        //     dom.style.backgroundColor = "green";
        // } else {
        //     dom.style.backgroundColor = "darkred";
        // }
    };

    //mouse leave while drag
    const handleDragLeave = (e) => {
        e.preventDefault();
        let dom = e.target;
        // dom.style.backgroundColor = "transparent";
    };

    //handle popup click (open popup and set currently clicked item)
    const handleProductClick = (event, product, rowIndex, i) => {
        if (!showProperty || clickedItem.itemIndex !== i || clickedItem.rowIndex !== rowIndex) {
            setClickedItem({
                product: product,
                itemIndex: i,
                rowIndex: rowIndex
            });
            setShowProperty(true);
        } else {
            setShowProperty(false);
        }
    };

    //handle delet from popup
    const handleDelete = () => {
        const product = clickedItem.product;
        const updatedRows = [...rowData.rows];
        const row = updatedRows[clickedItem.rowIndex];
        const clonedProduct = { ...product };

        row.data.splice(clickedItem.itemIndex, 1);

        // Update occupiedwidth and remainingwidth
        row.occupiedwidth -= parseInt(product.width);
        row.remainingwidth += parseInt(product.width);

        //calculate item counts
        calculateCounts("popupDeleteItem", clonedProduct);

        setRowData({ ...rowData, rows: updatedRows });

        // display toast when image removed successfully
        toast.success(`${product.name} removed successfully`, {
            position: toast.POSITION.BOTTOM_LEFT,
        });

        setShowProperty(false);
    };

    //calculate item counts (ramaining)
    const calculateCounts = (action, clonedProduct) => {
        if (action === "fromNewProducts" || action === "cloneFromShelfParent") {
            const heredata = storedProducts[clonedProduct.categoryIndex]?.productsData?.find((item) => {
                return item.id === clonedProduct?.defaultId;
            });
            heredata.count = heredata?.count - 1;
        }

        if (action === "popupDeleteItem") {
            let index = clickedItem.product.categoryIndex;
            const heredata = storedProducts[index]?.productsData?.find((item) => {
                return item.id === clickedItem.product?.defaultId;
            });
            heredata.count = heredata?.count + 1;
        }

        if (action === "dragDeleteItem") {
            const heredata = storedProducts[clonedProduct?.categoryIndex].productsData?.find((item) => {
                return item.id === clonedProduct?.defaultId;
            });
            heredata.count = heredata?.count + 1;
        }
    };

    //check counts when it drag fromy
    const isClonable = (product) => {
        const findData = storedProducts[product.categoryIndex].productsData.find((item) => {
            return item.id === product.defaultId;
        })
        if (findData?.count === 0) {
            return false;
        } else {
            return true;
        }
    };

    //set location id for every dropped item
    const locationId = () => {
        rowData.rows.map((item, rowIndex) => {
            return item.data.map((res, columnIndex) => {
                return res.locationId = `row-${rowIndex + 1}-${columnIndex + 1}`
            })
        })
    };

    //find clicked item stock count
    const currentCount = (data) => {
        const findData = storedProducts[data?.product?.categoryIndex]?.productsData.find((item) => {
            return item.id === data.product.defaultId
        })
        return findData?.count;
    };

    //find clicked item count in current row
    const totalInShelf = (data) => {
        const findData = rowData?.rows[data.rowIndex]?.data?.filter((item) => {
            return item.defaultId === data.product.defaultId
        })
        return findData?.length;
    };

    //active property sidebar
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    //total category counts
    const categoryTotalCount = (data) => {
        const totalCount = data.productsData.reduce((accumulator, product) => accumulator + product?.count, 0);
        return totalCount;
    };

    //hide property sidebar
    const hidePropertySidebar = () => {
        setShowProperty(false)
    };

    //import new file
    const handleImportClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    //set imported json to rowdata
    const handleFileImport = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const parsedData = JSON.parse(e.target.result);
                    setImportJsonData(parsedData);
                    setRowData(parsedData)
                } catch (error) {
                    console.error('Error parsing JSON file:', error);
                }
            };
            reader.readAsText(file);
        }
    };

    //zoom input range
    const zoomRange = (e) => {
        const zoomValue = parseInt(e.target.value);
        setZoomPercentage(zoomValue);
    };

    //zoomout
    const handleZoomOut = () => {
        const zoomValue = zoomPercentage - 25;
        setZoomPercentage(zoomValue);
    };

    //zoomin
    const handleZoomIn = () => {
        const zoomValue = zoomPercentage + 25;
        setZoomPercentage(zoomValue);
    };

    //hide adjustheight popup
    const hideAdjustHeightPopup = () => {
        setHeightAdjustPopup(false);
    };

    //adjust height of the rack
    const adjustHeight = (e, index) => {
        const isImage = e.target.tagName === 'IMG';
        const data = rowData.rows[index].data;

        if (!isImage) {
            if (data.length === 0) {
                setSelectedRack({ index: index, data: rowData.rows[index] });
                setHeightAdjustPopup(true);
            }
            if (data.length !== 0) {
                // empty row before adjust height
                toast.warning(`empty row before adjust height`, {
                    position: toast.POSITION.BOTTOM_LEFT,
                });
            }
        }
    };

    //set onchange value of the height
    const handleChangeHeight = (e) => {
        setEditedHeight(parseInt(e?.target.value));
    };

    //apply changes
    const applyHeight = () => {
        const updatedRows = [...rowData.rows];
        updatedRows[selectedRack.index].height = `${editedHeight}px`;
        setRowData({ ...rowData, rows: updatedRows });
        setHeightAdjustPopup(false);
    };

    //hint
    const Hint = () => {
        return (
            <div className="hintParent">
                <div className="bulbParent">
                    <img src={Bulb} alt="bulb" />
                    <p>Hint</p>
                </div>
                <ul>
                    <li>Hold ALT & Click the item to duplicate</li>
                    <li>Click and drag an item to move</li>
                    <li>Adjust Rack heihgt by click rack</li>
                </ul>
            </div>
        )
    };

    //body for properties
    const propertiesBody = () => {
        return (
            <div>
                <div className="propertiesWrapper">
                    <div>
                        <p className="propertyName">Item name:</p>
                        <p className="propertyDetails">{clickedItem.product?.name}</p>
                    </div>

                    <div>
                        <p className="propertyName">Row number:</p>
                        <p className="propertyDetails">{clickedItem.rowIndex + 1}</p>
                    </div>

                    <div>
                        <p className="propertyName">Stock status:</p>
                        <p className="propertyDetails">{currentCount(clickedItem) > 0 ? "instock" : "out of stock"}</p>
                    </div>

                    <div>
                        <p className="propertyName">Product in shelf:</p>
                        <p className="propertyDetails">{totalInShelf(clickedItem)}</p>
                    </div>

                    <div>
                        <p className="propertyName">Product in stock:</p>
                        <p className="propertyDetails"> {currentCount(clickedItem)} </p>
                    </div>

                    <div className="removebtnWrapper">
                        <button className="removebtn" onClick={() => handleDelete()}>Remove from shelf:</button>
                    </div>

                    {Hint()}

                </div>
            </div>
        )
    };

    //body for change height
    const changeHeight = () => {
        return (
            <div className="changeHeightParent">
                <p className="selectedRow">Product Rack - Row {selectedRack.index + 1} </p>

                <div className="adjustWidthWrapper" >
                    <p>Width : </p>
                    <input
                        className="adjustWidth"
                        type="number"
                        defaultValue={parseInt(rowData?.width)}
                        disabled
                    />
                    <p>px</p>
                </div>

                <div className="adjustHeightWrapper">
                    <p>Height : </p>
                    <input
                        className="adjustHeight"
                        type="number"
                        defaultValue={parseInt(selectedRack?.data?.height)}
                        onChange={(e) => handleChangeHeight(e)}
                    />
                    <p>px</p>
                </div>

                <div className="btnParent">
                    <button className="applyBtn" onClick={applyHeight}>Apply changes</button>
                    <button className="cancelBtn" onClick={hideAdjustHeightPopup}>Cancel</button>

                </div>

                {Hint()}
            </div>
        )
    };

    //logs
    console.log("rowData", rowData);
    console.log("setStoredProducts", storedProducts);
    console.log("clickedItem", clickedItem);
    console.log("selectedRack", selectedRack);

    //listners for (remove image when dropped outside shelf)
    document.addEventListener("drop", handleDocumentDrop);
    document.addEventListener("dragover", handleDocumentDragOver);

    // Add a resize event listener to get the width when the screen size changes
    window.addEventListener("resize", getDivWidth);

    return (
        <>
            <div className="newshelf">
                <div className="brand">
                    <img src={Company} alt="brand" />
                </div>
                <div className="header">
                    <div>
                        <ul className="listparent" style={{ marginTop: "revert" }}>
                            <li> <img src={File} alt="file" /> New</li>
                            <li onClick={handleImportClick}> <img src={imports} alt="import" /> Import</li>
                            <li> <img src={undo} alt="undo" />Undo</li>
                            <li> <img src={redo} alt="redo" /> Redo</li>
                        </ul>
                    </div>
                    <div>
                        <ul className="listparent">
                            <li className="convertpdf"
                                onClick={() => {
                                    handleConvertToPDF("shelfParent", "planogram");
                                    toast.success(`Converted successfully`, {
                                        position: toast.POSITION.BOTTOM_LEFT,
                                    });
                                }}
                            > <img src={pdf} alt="pdf" /> Convert PDF</li>
                            <li className="exportjson"
                                onClick={() => {
                                    handleExport(rowData);
                                    toast.success(`Exported successfully`, {
                                        position: toast.POSITION.BOTTOM_LEFT,
                                    });
                                }}
                            > <img src={exports} alt="export" /> Export as JSON</li>
                        </ul>
                        {/* Hidden file input to handle file selection */}
                        <input
                            type="file"
                            accept=".json"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileImport}
                        />
                    </div>
                </div>

                <div className="content">
                    <div className="productlistParent">
                        <div className="searchWrapper">
                            <p className="categorytitle">Products</p>

                            <div className={`input-box ${isSearchOpen ? 'open' : ''}`}>
                                <input type="text" placeholder="Search..." />
                                <span className="searchImage" onClick={() => setIsSearchOpen(true)}>
                                    <img
                                        className="searchImage-icon"
                                        src={search}
                                        alt="img"
                                    />
                                </span>
                                <i onClick={() => setIsSearchOpen(false)} className="uil uil-times close-icon">
                                    <CloseIcon />
                                </i>
                            </div>
                        </div>

                        <div className="accordionWrapper">
                            {storedProducts.map((item, index) => {
                                return (
                                    <>
                                        <Accordion className={`accordionParent ${expanded === `panel${index}` ? "active" : ""}`} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <p className="accordionTitle" sx={{ width: '33%', flexShrink: 0 }}>
                                                    {item.name} <span className={expanded === `panel${index}` ? "isOpened" : "isNotOpened"}>{categoryTotalCount(item)}</span>
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className="accordionContent">
                                                <div className="imageParent">
                                                    {item.productsData.map((res) => {
                                                        return (
                                                            <div className="newProductsImageWrapper">
                                                                <button
                                                                    key={res.id}
                                                                    className="newProductChild"
                                                                    onDragStart={(e) => handleDragStart(e, res, "fromNewProducts")}
                                                                    draggable={res?.count > 0}
                                                                    disabled={res?.count === 0}
                                                                    style={{
                                                                        cursor: res?.count === 0 ? "not-allowed" : "pointer",
                                                                        height: "90px",
                                                                        width: "90px"
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={res.img}
                                                                        className="newProductsImage"
                                                                        style={{
                                                                            // height: calculatePercentageInPixels(res.height, perVal),
                                                                            // width: calculatePercentageInPixels(res.width, perVal),
                                                                            opacity: res?.count === 0 ? 0.3 : 1
                                                                        }}
                                                                        alt={res.name}
                                                                        draggable={res?.count > 0}
                                                                    />
                                                                    <span className="count">{res?.count}</span>
                                                                    {res.count === 0 && <div className="outofstock">out of stock</div>}
                                                                </button>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                    <div className="productshelfParent">
                        <div className="actionsParent">
                            <div className="zoomParent">
                                <div>
                                    <img
                                        src={Zoomout}
                                        alt="zoomout"
                                        onClick={handleZoomOut}
                                        style={{ opacity: zoomPercentage === 25 ? "0.3" : "1", pointerEvents: zoomPercentage === 25 ? "none" : "all" }}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="range"
                                        id="zooming"
                                        name="zooming"
                                        value={zoomPercentage}
                                        min={25}
                                        max={150}
                                        onChange={(e) => zoomRange(e)}
                                    />
                                </div>
                                <div>
                                    <img
                                        src={Zoomin}
                                        alt="zoomin"
                                        onClick={handleZoomIn}
                                        style={{ opacity: zoomPercentage === 150 ? "0.3" : "1", pointerEvents: zoomPercentage === 150 ? "none" : "all" }}
                                    />
                                </div>
                            </div>

                            <div
                                onClick={toggleFullScreen}
                                className="fullscreenParent">
                                <img
                                    src={Fullscreen}
                                    alt="Fullscreen"
                                />
                            </div>
                        </div>

                        <div className={`shelfImageWrapper ${isFullScreen ? "active" : ""} `} ref={shelfParentRef}>
                            <div className={`shelfParent`}>
                                {rowData?.rows?.map((res, rowIndex) => {
                                    return (
                                        <div
                                            ref={rowParentRef}
                                            key={rowIndex}
                                            id={`table-${rowIndex}`}
                                            draggable={false}
                                            className="rowParent"
                                            style={{
                                                height: calculatePercentageInPixels(res.height, zoomPercentage),
                                                width: calculatePercentageInPixels(rowData.width, zoomPercentage),
                                                // width: rowData.width,
                                                position: 'relative',
                                                background: `url(${Rectangles}) bottom center no-repeat`,
                                                backgroundSize: '100% 20px',
                                                cursor: rowData.rows[rowIndex].data.length === 0 ? "pointer" : "default"
                                            }}
                                            onDrop={(e) => handleDrop(e, rowIndex, res)}
                                            onDragOver={(e) => {
                                                handleDragOver(e, res, rowIndex);
                                            }}
                                            onDragLeave={(e) => {
                                                handleDragLeave(e);
                                            }}
                                            onMouseDown={(e) => checkPosition(e)} // X and Y position (onClick)
                                            onClick={(e) => adjustHeight(e, rowIndex)}
                                        >

                                            {/* ::before pseudo-element */}
                                            <div
                                                className="beforeElement"
                                                style={{
                                                    content: '""',
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '16px',
                                                    height: '100%',
                                                    background: `url(${shelfHole})`,
                                                    backgroundColor: "#A2A1A1"
                                                }}
                                            />

                                            {/* ::after pseudo-element */}
                                            <div
                                                className="afterElement"
                                                style={{
                                                    content: '""',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    width: '16px',
                                                    height: '100%',
                                                    background: `url(${shelfHole})`,
                                                    backgroundColor: "#A2A1A1"
                                                }}
                                            />

                                            {res.data?.map((product, i) => {
                                                const style = product.position
                                                    ? {
                                                        left: calculatePercentageInPixels(
                                                            product.position.x,
                                                            zoomPercentage
                                                        ),
                                                        // top: calculatePercentageInPixels(
                                                        //   product.position.y,
                                                        //   perVal
                                                        // ),
                                                    }
                                                    : null;
                                                return (
                                                    <>
                                                        <img
                                                            key={product.id}
                                                            style={{
                                                                width: calculatePercentageInPixels(
                                                                    product.width,
                                                                    zoomPercentage
                                                                ),
                                                                height: calculatePercentageInPixels(
                                                                    product.height,
                                                                    zoomPercentage
                                                                ),
                                                                position: "absolute",
                                                                bottom: "20px",
                                                                ...style,
                                                            }}
                                                            draggable
                                                            onDragStart={(e) =>
                                                                handleDragStart(
                                                                    e,
                                                                    product,
                                                                    "fromShelfParent",
                                                                    rowIndex
                                                                )
                                                            }
                                                            src={product.img}
                                                            alt={product.name}
                                                            onClick={(e) => {
                                                                handleProductClick(e, product, rowIndex, i)
                                                            }}
                                                            className={`${showProperty && product.defaultId === clickedItem.product.defaultId ? "currentlyClicked" : ""}`}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>

                            <div>
                                <img
                                    src={Base}
                                    alt="base"
                                    style={{
                                        marginLeft: "8px",
                                        width: calculatePercentageInPixels("1345px", zoomPercentage),
                                        height: calculatePercentageInPixels("200px", zoomPercentage),
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    {false ? <div className="producttemplateParent">
                        <p className="categorytitle">Product Template</p>

                        <div className="shelflistWrapper" >
                            <div className="shelflistParent">
                                <div>
                                    <img src={shelf} alt="shelf" />
                                </div>
                                <div className="shelfDetails">
                                    <span className="selectedParent"> <h6>Productshelf 1</h6> <p className="selected">selected</p> </span>
                                    <p>Height : 11ft</p>
                                    <p>Width : 10ft</p>
                                    <p>Length : 18ft </p>
                                    <p>No of Racks: 4</p>
                                </div>
                            </div>

                            <div className="shelflistParent">
                                <div>
                                    <img src={shelf} alt="shelf" />
                                </div>
                                <div className="shelfDetails">
                                    <span className="selectedParent"> <h6>Productshelf 2</h6> <p className="selected">selected</p> </span>
                                    <p>Height : 11ft</p>
                                    <p>Width : 10ft</p>
                                    <p>Length : 18ft </p>
                                    <p>No of Racks: 6</p>
                                </div>
                            </div>

                            <div className="shelflistParent">
                                <div>
                                    <img src={shelf} alt="shelf" />
                                </div>
                                <div className="shelfDetails">
                                    <span className="selectedParent"> <h6>Productshelf 3</h6> <p className="selected">selected</p> </span>
                                    <p>Height : 11ft</p>
                                    <p>Width : 10ft</p>
                                    <p>Length : 18ft </p>
                                    <p>No of Racks: 3</p>
                                </div>
                            </div>

                        </div>

                    </div> : null}
                </div>

                <OffcanvasPopup
                    show={showProperty}
                    hide={hidePropertySidebar}
                    title={"Properties"}
                    classname={"productProtertiesParent"}
                    OffcanvasBody={propertiesBody}
                />

                <OffcanvasPopup
                    show={adjustHeightPopup}
                    hide={hideAdjustHeightPopup}
                    title={"Properties"}
                    classname={"productProtertiesParent"}
                    OffcanvasBody={changeHeight}
                />

            </div>
            <ToastContainer />

        </>
    );
}
export default PlanogramShelf;